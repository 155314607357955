import cx from 'classnames';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useRef } from 'react';
import { ComponentProps, forwardRef, FunctionComponent } from 'react';
import Select, { Styles, GroupTypeBase, components } from 'react-select';

import useIsMobile from '../../../../hooks/use-is-mobile';
import useLinkProps from '../../../../hooks/use-link-props';
import Chevrelon from '../../icons/cheverlon';
import BPImage from '../../image';
import VehiclePreview from '../../vehicle-preview';

const colors = require(`../../../../../tailwing/${process.env.NEXT_PUBLIC_TENANT}.json`);

export const CustomOptionIcon: FunctionComponent<
  ComponentProps<typeof components.Option>
> = (props) => {
  const { data } = props;

  return (
    <components.Option {...props}>
      <div className={cx('flex', 'justify-between', 'items-center')}>
        <div className={cx('flex-1', 'truncate')}>{data.label}</div>

        <div
          className={cx('shrink-0', 'h-6', 'w-6', 'rounded-xl', 'shadow-md')}
        >
          <BPImage
            src={data.icon}
            width={24}
            height={24}
            layout="intrinsic"
            alt={data.label}
            className="shadow-md"
          />
        </div>
      </div>
    </components.Option>
  );
};

export const CustomSingleValueIcon: FunctionComponent<
  ComponentProps<typeof components.SingleValue>
> = (props) => {
  const { data } = props;

  return (
    <>
      <components.Placeholder {...props} isFocused={true}>
        {props.selectProps.placeholder}
      </components.Placeholder>
      <components.SingleValue {...props}>
        <div className={cx('flex', 'gap-4')}>
          <div className={cx('flex-1', 'truncate')}>{data.label}</div>

          <div
            className={cx(
              'shrink-0',
              'relative',
              '-mt-2',
              'h-6',
              'w-6',
              'rounded-xl',
              'shadow-md'
            )}
          >
            <BPImage
              src={data.icon}
              width={24}
              height={24}
              layout="intrinsic"
              alt={data.label}
            />
          </div>
        </div>
      </components.SingleValue>
    </>
  );
};

export const CustomSingleValue: FunctionComponent<
  ComponentProps<typeof components.SingleValue>
> = (props) => {
  const { data } = props;

  return (
    <>
      <components.Placeholder {...props} isFocused={true}>
        {props.selectProps.placeholder}
      </components.Placeholder>
      <components.SingleValue {...props}>
        <div>{data.label}</div>
      </components.SingleValue>
    </>
  );
};

export const CustomOptionBrand: FunctionComponent<
  ComponentProps<typeof components.Option>
> = (props) => {
  const { data } = props;

  return (
    <components.Option {...props}>
      <VehiclePreview
        modeSelect={true}
        sizeLogo="small"
        info={{ brand: { name: data.label, logo_url: data.logo } }}
      />
    </components.Option>
  );
};

export const CustomOptionVehicle: FunctionComponent<
  ComponentProps<typeof components.Option>
> = (props) => {
  const { data } = props;

  return (
    <components.Option {...props}>
      <VehiclePreview
        sizeLogo="small"
        info={{
          brand: { name: data.brand.label, logo_url: data.brand.logo },
          model: { name: data.model.label },
          ...(data.version?.label && { version: { name: data.version.label } }),
        }}
      />
    </components.Option>
  );
};

export const CustomMenu: FunctionComponent<
  ComponentProps<typeof components.MenuList>
> = (props) => {
  // TODO: THIS LOGIC MUST BE CHECKED AGAIN
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  // to mobile porpose we need to check if back was triggered to make history back
  const getLink = useLinkProps();
  const backTriggered = useRef<boolean>(false);
  const { query, route, asPath, beforePopState, push, back, ...rest } =
    useRouter();

  useEffect(() => {
    if (isMobile && route !== '/private-area') {
      // to ensure user click on back and the history does not go back since this is not a route
      push(getLink(route, { ...query }), undefined, {
        shallow: true,
      });

      beforePopState(() => {
        backTriggered.current = true;
        props.selectProps.onMenuClose();
      });

      return () => {
        if (!backTriggered.current) {
          back();
        }
      };
    }
  }, [isMobile]);

  useEffect(() => {
    document.body.style.overflow = isMobile ? 'hidden' : 'auto';
    return () => (document.body.style.overflow = 'auto');
  }, [isMobile]);

  return isMobile && route !== '/private-area' ? (
    <components.MenuList {...props}>
      <div className="fixed h-full w-full top-0 left-0 bg-white z-50 overflow-scroll">
        <div>
          Search
          <input
            // placeholder={props.selectProps.placeholder}
            value={props.selectProps.inputValue}
            onFocus={(e) => {
              e.stopPropagation();
            }}
            onChange={(e) => {
              e.stopPropagation();
              props.selectProps.onInputChange(e.target.value, undefined);
            }}
          />
        </div>
        <div className="text-base py-4 font-bold">Select Brand:</div>
        {props.children}
      </div>
    </components.MenuList>
  ) : (
    <components.MenuList {...props} />
  );
};

export const CustomMenuWithoutMobile: FunctionComponent<
  ComponentProps<typeof components.MenuList>
> = (props) => {
  const { query, route, asPath, beforePopState, push, back, ...rest } =
    useRouter();

  return <components.MenuList {...props} />;
};

export const CustomSingleValueBrand: FunctionComponent<
  ComponentProps<typeof components.SingleValue>
> = (props) => {
  const { data } = props;

  return (
    <>
      <components.Placeholder {...props} isFocused={true}>
        {props.selectProps.placeholder}
      </components.Placeholder>
      <components.SingleValue {...props}>
        <VehiclePreview
          modeSelect
          centerLogoSelect={true}
          sizeLogo="small"
          info={{ brand: { name: data.label, logo_url: data.logo } }}
        />
      </components.SingleValue>
    </>
  );
};

export const CustomOptionModelsVersions: FunctionComponent<
  ComponentProps<typeof components.Option>
> = (props) => {
  const { data } = props;

  return (
    <components.Option {...props}>
      <span className="font-bold">{data.label}</span>{' '}
      <span className="text-xs">
        [{data.start_date}-{data.end_date}]
      </span>
    </components.Option>
  );
};

export const CustomSingleValueModelsVersions: FunctionComponent<
  ComponentProps<typeof components.SingleValue>
> = (props) => {
  const { data } = props;

  return (
    <>
      <components.Placeholder {...props} isFocused={true}>
        {props.selectProps.placeholder}
      </components.Placeholder>
      <components.SingleValue {...props}>
        <span className="font-bold">{data.label}</span>{' '}
        <span className="text-xs">
          [{data.start_date}-{data.end_date}]
        </span>
      </components.SingleValue>
    </>
  );
};

export const CustomMenuParts: FunctionComponent<
  ComponentProps<typeof components.MenuList>
> = (props) => {
  return <components.MenuList {...props} />;
};

export const CustomSingleValueParts: FunctionComponent<
  ComponentProps<typeof components.SingleValue>
> = (props) => {
  const { data } = props;

  return (
    <>
      <components.Placeholder {...props} isFocused={true}>
        {props.selectProps.placeholder}
      </components.Placeholder>
      <components.SingleValue {...props}>
        <span className="font-bold">{data.label}</span>{' '}
      </components.SingleValue>
    </>
  );
};

export const CustomSingleValuePartsWithGroup: FunctionComponent<
  ComponentProps<typeof components.SingleValue>
> = (props) => {
  const { data } = props;

  return (
    <>
      <components.Placeholder {...props} isFocused={true}>
        {data.group}
      </components.Placeholder>
      <components.SingleValue {...props}>
        <span className="font-bold">{data.label}</span>{' '}
      </components.SingleValue>
    </>
  );
};

export const CustomOptionParts: FunctionComponent<
  ComponentProps<typeof components.Option>
> = (props) => {
  const { data } = props;

  return (
    <components.Option {...props}>
      <span className="font-bold">{data.label}</span>
    </components.Option>
  );
};

export const CustomDownChevron: FunctionComponent<
  ComponentProps<typeof components.DropdownIndicator>
> = (props) => (
  <components.DropdownIndicator {...props}>
    <Chevrelon className={cx('w-3')} />
  </components.DropdownIndicator>
);

export const CustomOptionPaymentMethod: FunctionComponent<
  ComponentProps<typeof components.Option>
> = (props) => {
  const { data } = props;

  return (
    <components.Option {...props}>
      <div className={'flex justify-between items-center'}>
        <p>{data.name}</p>
        {data.image}
      </div>
    </components.Option>
  );
};

export const CustomSingleValuePaymentMethod: FunctionComponent<
  ComponentProps<typeof components.SingleValue>
> = (props) => {
  const { data } = props;

  return (
    <>
      <components.Placeholder {...props} isFocused={true}>
        {props.selectProps.placeholder}
      </components.Placeholder>
      <components.SingleValue {...props}>
        <div
          className={'flex justify-between items-center whitespace-pre-wrap'}
        >
          <div className="h-6 overflow-hidden text-ellipsis">{data.name}</div>
          <div className="shrink-0">{data.image}</div>
        </div>
      </components.SingleValue>
    </>
  );
};

export const CustomOptionAddress: FunctionComponent<
  ComponentProps<typeof components.Option>
> = (props) => {
  const { data } = props;

  return (
    <components.Option {...props}>
      {data.address ? (
        <div
          className={cx('not-italic flex items-center lg:grid lg:grid-cols-5')}
        >
          <span className={'mr-6 lg:col-span-2'}>
            <b>{data.description}</b>
            <br />
            {data.address.name}
          </span>
          <span className={'lg:col-span-3'}>
            {data.address.street}
            <br />
            {data.address.zip_code}, {data.address.city}
            <br />
            {data.address.shipping_destination == 7
              ? data.address.country_readable
              : data.address.shipping_destination_readable}
          </span>
        </div>
      ) : (
        data.description
      )}
    </components.Option>
  );
};

export const CustomSingleValueAddress: FunctionComponent<
  ComponentProps<typeof components.SingleValue>
> = (props) => {
  const { data } = props;

  return (
    <>
      <components.Placeholder {...props} isFocused={true}>
        <div className="truncate">{props.selectProps.placeholder}</div>
      </components.Placeholder>
      <components.SingleValue {...props}>
        <div className="truncate">
          {[
            data.street,
            data.zip_code,
            data.city,
            data === 'billing' || data.shipping_destination == 7
              ? data.country_readable
              : data.shipping_destination_readable,
          ].join(', ')}
        </div>
      </components.SingleValue>
    </>
  );
};

const customStyles: Partial<Styles<any, boolean, GroupTypeBase<any>>> = {
  control: (_, { isDisabled, isFocused }) => ({
    display: 'flex',
    borderBottom: `1px ${
      isFocused ? colors['brand-100'] : colors['neutral-30']
    } solid`,
    height: '48px',
    backgroundColor: isDisabled ? colors['neutral-10'] : colors['white'],
    '&:hover': isDisabled
      ? {}
      : {
          background: !isFocused ? colors['brand-10'] : colors['white'],
        },
    cursor: isDisabled ? 'not-allowed' : 'pointer',
  }),
  placeholder: (provided, { isDisabled, isFocused }) => ({
    ...provided,
    transform: isFocused
      ? 'scale(0.8) translateY(-28px)'
      : 'scale(1) translateY(-50%)',
    transition: 'transform 0.3s ease-in-out',
    transformOrigin: 'left top',
    marginLeft: 0,
    marginRight: 0,
    letterSpacing: 0,
    ...(isFocused && { color: colors['brand-100'] }),
  }),
  singleValue: (provided) => ({
    ...provided,
    maxWidth: 'calc(100% - 16px)',
    width: '100%',
    top: 'calc(50% + 7px)',
    marginLeft: 0,
    overflow: 'visible',
  }),
  input: (provided) => ({
    ...provided,
    fontSize: '16px',
    marginBottom: -10,
  }),
  valueContainer: (provided, { isDisabled }) => ({
    ...provided,
    padding: '2px 16px',
    fontSize: '16px',
    letterSpacing: '0.7px',
    textAlign: 'left',
    cursor: isDisabled ? 'not-allowed' : 'pointer',
    pointerEvents: 'auto',
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  dropdownIndicator: (provided, { isDisabled }) => ({
    ...provided,
    padding: '16px',
    color: isDisabled ? colors['neutral-50'] : colors['neutral-80'],
    cursor: isDisabled ? 'not-allowed' : 'pointer',
    pointerEvents: 'auto',
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: 0,
    marginBottom: 0,
    borderRadius: 0,
    textAlign: 'left',
    backgroundColor: colors['white'],
  }),
  option: (provided, { isFocused, data, getValue }) => {
    const selected = getValue();
    const isDisabled = data.disabled;
    let isSelected = isFocused;

    if (selected && selected[0]?.id) {
      isSelected = data.value === getValue()[0].id;
    } else if (selected && selected[0]?.value) {
      isSelected = data.value === getValue()[0].value;
    }

    return {
      ...provided,
      ':active': {
        background: isDisabled ? colors['neutral-10'] : colors['brand-20'],
      },
      ':hover': {
        background: isDisabled ? colors['neutral-10'] : colors['brand-10'],
      },
      padding: '12px 16px',
      color: colors['neutral-80'],
      fontSize: '14px',
      background: isDisabled
        ? colors['neutral-10']
        : isSelected
        ? colors['brand-20']
        : 'transparent',
      cursor: isDisabled ? 'not-allowed' : 'pointer',
    };
  },
  groupHeading: (provided) => ({
    ...provided,
    padding: '0px 16px',
    color: 'black',
    fontSize: '24px',
  }),
};
const customErrorStyles = { ...customStyles };
customErrorStyles.placeholder = (provided, { isDisabled, isFocused }) => ({
  ...provided,
  transform: isFocused
    ? 'scale(0.8) translateY(-28px)'
    : 'scale(1) translateY(-50%)',
  transition: 'transform 0.3s ease-in-out',
  transformOrigin: 'left top',
  marginLeft: 0,
  marginRight: 0,
  letterSpacing: 0,
  fontSize: 16,
  color: colors['red-100'],
  ...(isFocused && { color: colors['red-100'] }),
});
(customErrorStyles.dropdownIndicator = (
  provided,
  { isDisabled, isFocused }
) => ({
  ...provided,
  color: colors['red-100'],
  padding: '16px',
})),
  (customErrorStyles.control = (_, { isDisabled, isFocused }) => ({
    display: 'flex',
    borderBottom: `1px ${colors['red-100']} solid`,
    height: '48px',
    backgroundColor: isDisabled ? colors['neutral-10'] : colors['white'],
    cursor: 'pointer',
    '&:hover': {
      background: !isFocused ? colors['brand-10'] : colors['white'],
    },
  }));

const CustomSelector: FunctionComponent<ComponentProps<typeof Select>> =
  forwardRef((props, ref) => {
    if (props.error) {
      return (
        <div>
          <Select ref={ref} {...props} styles={customErrorStyles} />
          {typeof props.error !== 'boolean' && (
            <span
              className={cx(
                'px-4',
                'lg:px-0',
                'block',
                'text-red-100',
                'text-xs',
                'leading-3',
                'py-3'
              )}
            >
              {props.error}
            </span>
          )}
        </div>
      );
    }
    return <Select ref={ref} {...props} styles={customStyles} />;
  });

CustomSelector.displayName = 'CustomSelector';

export default CustomSelector;
